.ola_parceiroecom {
    color: #5C42DB;
    font-weight: bolder;
    font-size: 2rem;
}
.ola_busca {
    color: #5C42DB;
    font-weight: bolder;
    font-size: 1.5rem;
}
.topo-menu {
    height: 100px !important;
}
.hr {
    color: #5C42DB;
    border-color: #5C42DB;
}
.botao-chamativo {
    width: 100%;
    color: #5C42DB !important;
}
.icone-busca {
    width: 2rem;
    position: absolute;
    bottom: -17px;
    left: 85%;
}
#razao_social {
    padding-right: 3rem;
}
.h1-titulo {
    font-size: 1.2rem;
    text-align: center;
}
.papelfinger-webapp {
    width: 50%;
    margin: 0 auto;
} 
.footer-vazio {
    background-color: #5C42DB;
}
.botao-voltar {
    border-color: #5C42DB !important;
    background-color: #F5F5F5 !important;
    color: #5C42DB !important;
}
.alinha-direita {
    text-align: right;
    max-width: 100%;
    width: 100%;
}