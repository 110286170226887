.MuiStepIcon-root.MuiStepIcon-completed {
    color:  #5C42DB !important;
}
.MuiStepper-root {
    display: flex;
    padding: 24px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.MuiStepLabel-label {
    color: #000000 !important;
}
.barra-titulo {
    background-color: #5C42DB;
    padding: 0.6rem;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.alinha-direita{
    text-align: right;
}
.alinha-centro{
    text-align: center;
}
.input-hidden {
    display: none;
}
.lista-upload{
    margin-bottom: 1rem !important;
}
.icone-aprovado {
    color: #00b050 !important;
}
.icone-nao-enviado {
    color: #fdc722 !important;
}
.icone-recusado {
    color: #ff0000 !important;
}
.icone-avaliacao {
    color: #5C42DB !important;
}
.barra-legenda {
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
    max-width: 100%;
}
.legenda-titulo {
    float: none !important;
}
.nome_arquivo{
    position: relative;
    bottom: 0.4rem;
}
.lixeira{
    position: relative;
    bottom: 0.4rem;
}

.botao-voltar {
    background: #5C42DB !important;
    color: #fff !important;
    margin: 1rem !important;
    font-size: 0.7rem;
}
.box-voltar {
    width: 100%;
    max-width: 100%;
    text-align: right;
}
.botoes {
    text-align: right;
}
.botoes2 {
    text-align: left;
}
.titulo_apoio {
    text-align: left;
    width: 100%;
    max-width: 100%;
    padding-top: 1rem;
    font-size: 30px;
    color: #5c42db !important;
}
.papelfinger :hover {
    cursor: pointer;
}