.ola_parceiroecom {
    color: #5C42DB;
    font-weight: bolder;
    font-size: 2rem;
}
.ola_busca {
    color: #5C42DB;
    font-weight: bolder;
    font-size: 1.5rem;
}
.ola_busca_perdido {
    color: #e74c3c;
    font-weight: bolder;
    font-size: 1.5rem;
}
.comentarioperdido {
    text-align: justify;
    padding-left: 3rem;
    padding-right: 3rem;
}
.codigomotivo {
    font-weight: bolder;
    text-align: justify;
    padding-left: 3rem;
    padding-right: 3rem;
}
.topo-menu {
    height: 100px !important;
}
.hr {
    color: #5C42DB;
    border-color: #5C42DB;
}
.botao-chamativo {
    width: 100%;
    color: #5C42DB !important;
}
.icone-busca {
    width: 2rem;
    position: absolute;
    bottom: -17px;
    left: 85%;
}
#razao_social {
    padding-right: 3rem;
}
.h1-titulo {
    font-size: 1.2rem;
    text-align: center;
}
.papelfinger-webapp {
    width: 50%;
    margin: 0 auto;
} 
.footer-vazio {
    background-color: #5C42DB;
}
.botao-voltar {
    border-color: #5C42DB;
    background-color: #F5F5F5;
    color: #5C42DB;
}
.container-webpp {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.step-content {
    padding-left: 0;
    border: none;
}

/* *:required {
    background-color: #d6d6d6;
  } */

.botao-voltar-webapp {
    margin-top: 1rem;
    border-color: #5C42DB;
    background-color: #F5F5F5;
    color: #5C42DB;
    margin-right: 1rem;
}
.botao-salvar-webapp {
    margin-top: 1rem;
    border-color: #5C42DB;
    background-color: #5C42DB;
    color: #EAEF22;
}
.alinha-centro{
    text-align: center;
}
.alinha-direira-webapp {
    text-align: right;
}
.alinha-esquerda-webapp {
    text-align: left;
}

.carregando-botao-webapp {
    width: 20px !important;
    height: 20px !important;
    color: #EAEF22 !important;
    margin-left: 0.6rem;
}

.carregando-botao-webapp2 {
    width: 20px !important;
    height: 20px !important;
    color: #000000 !important;
    margin-left: 0.6rem;
}

.box-cenario {
    background-color: #f2f2f2;
    padding: 1rem;
    margin-bottom: 1rem;
    min-height: 10rem;
}