.ola_parceiroecom {
    color: #5C42DB;
    font-weight: bolder;
    font-size: 2rem;
    text-align: center;
}
.ola_parceiroecom p {
    text-align: 1.4rem;
    font-weight: bold;
}
.ola_busca {
    color: #5C42DB;
    font-weight: bolder;
    font-size: 1.5rem;
}
.ola_busca_reprovados {
    color: #e74c3c;
    font-weight: bolder;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 2rem;
}
.cell-reprovados {
    background-color: #fb786a;
}
.topo-menu {
    height: 100px !important;
}
.tabelao {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}
.hr {
    color: #5C42DB;
    border-color: #5C42DB;
}
.botao-chamativo {
    width: 100%;
    color: #5C42DB !important;
}
.icone-busca {
    width: 2rem;
    position: absolute;
    bottom: -17px;
    left: 85%;
}
#razao_social {
    padding-right: 3rem;
}
.h1-titulo {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1rem;
}
.papelfinger-webapp {
    width: 50%;
    margin: 0 auto;
} 
.footer-vazio {
    background-color: #5C42DB;
}
.botao-voltar {
    border-color: #5C42DB;
    background-color: #F5F5F5;
    color: #5C42DB;
}
.container-webpp {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.step-content {
    padding-left: 0 !important;
    border: none !important;
    border-left: none !important;
}
.obrigatorio-webapp {
    background-color: #d6d6d6 !important;
}
/* *:required {
    background-color: #d6d6d6 !important;
  } */

.botao-voltar-webapp {
    margin-top: 1rem !important;
    border-color: #5C42DB !important;
    background-color: #F5F5F5 !important;
    color: #5C42DB !important;
    margin-right: 1rem !important;
}
.botao-salvar-webapp {
    margin-top: 1rem !important;
    border-color: #5C42DB !important;
    background-color: #5C42DB !important;
    color: #EAEF22 !important;
}
.alinha-centro{
    text-align: center !important;
} 
.botao-fatura-webapp {
    margin-top: 1rem !important;
    border-color: #5C42DB !important;
    background-color: #5C42DB !important;
    color: #EAEF22 !important;
    min-width: 100% !important;
    min-height: 4rem;
}
.stepzaoWebapp {
    width: 100%;
    text-align: center;
    padding: 0 !important;
}

.stepzaoWebapptopo {
    flex-direction: column-reverse !important;
    text-align: center;
    padding: 0 !important;
}

.MuiStepConnector-lineVertical {
    min-height: 0 !important;
    border-left-style: none !important;
    border-left-width: 0 !important;
}
.box-captura-webapp {
    text-align: left;
    padding-top: 2rem;
}
.box-ver-webapp {
    padding-top: 1rem;
    padding-bottom: 2rem;
}
.ola_busca-webapp {
    color: #5C42DB;
    font-weight: bolder;
    font-size: 1.5rem;
    text-align: center;
    max-width: 100%;
}
.nome-fantasia-webapp {
    font-weight: bold;
    text-align: left;
    font-size: 1.4rem;
}
.oculto-webapp {
    display: none;
}
.voltar-para-home-webapp {
    margin-top: 3rem;
}
.image-preview img {
    max-width: 100%;
}
.headerwebapp {
    height: 96px;
    box-shadow: none;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    background-color: #5C42DB !important;
}
.icone-captura {
    margin: 0 0 auto;
    width: 2rem;
}
.icone-captura-linha {
    margin: 0 0 auto;
    width: 2rem;
    position: relative;
    top: 10px;
}
.texto-captura {
    text-align: center;
}
.box-captura {
    width: 100%;
    max-width: 100%;
    text-align: center;
}
.espacocima {
    margin-top: 1rem;
}
.empresascadastradaswebapp {
    background: rgb(51, 102, 204) !important;
    color: #F5F5F5 !important;
    text-align: center;
    height: 5rem !important;
}

.indicacoesrealizadaswebapp {
    background: rgb(220, 57, 18) !important;
    color: #F5F5F5 !important;
    text-align: center;
    height: 5rem !important;
}
.emnegociacaowebapp {
    background: rgb(255, 153, 0) !important;
    color: #F5F5F5 !important;
    text-align: center;
    height: 5rem !important;
}
.emvalidacaowebapp {
    background: #0099c6 !important;
    color: #F5F5F5 !important;
    text-align: center;
    height: 5rem !important;
}
.listapendencia{
    text-align: left;
}
.listapendencia_sembola{
    text-align: left;
    
}
.listapendencia_sembola li{
    text-align: left;
    list-style-type: none;
}
.clientesfechadoswebapp {
    background: rgb(16, 150, 24) !important;
    color: #F5F5F5 !important;
    text-align: center;
    height: 5rem !important;
}
.negocioPerdidowebapp {
    background: rgb(153, 0, 153) !important;
    color: #F5F5F5 !important;
    text-align: center;
    height: 5rem !important;
}
.numeroswebapp {
    font-weight: bold;
    margin: 0 0 auto;
    font-size: 2rem !important;
}
.legendagraficowebapp {
    font-size: 0.67rem !important;
}
.boxOlho {
    margin-top: 2rem;
}
.gridWebApp {
    padding-right: 6px !important;
}

.fotocaptura {
    max-width: 100%;
    margin-top: 1rem;
}

.header-cenario {
    background-color: #EAEF22 !important;
    border-color: #000000;
    border-style: solid;
    border-radius: 10px;
    min-height: 4.2rem;
    padding-top: 1rem;
    padding-left: 0.6rem;
}

.cor-radio {
    margin-right: 1rem !important;
    width: 2rem;
    height: 2rem;
    position: relative;
    bottom: 0.6rem;
}

.tituloresumo {
    font-weight: bold;
    color: #5C42DB;
    font-size: 1.1rem;
}
.textoresumo {
    font-size: 1.2rem;
}
.caixa-resumo {
    text-align: left;
}
.status_assinatura_wepapp {
    font-size: 1.1rem;
}
.stepzaoWebapptopo {
    flex-direction: column-reverse !important;
}
.box-previsao {
    color: black;
    font-size: 2.6rem;
    font-weight: bold;
    text-align: center;
}
.div-enviado {
    text-align: center;
}
.parabenswebapp{
    font-size: 1.4rem;
    color: #5C42DB;
    font-weight: bold;
}
.texto_logo_acima{
    padding: 0;
    margin: 0;
    position: relative;
    left: 1rem;
    top: 15px;
}
.caixa-resumo h2 {
    font-weight: bold;
    color: #5C42DB;
    font-size: 1.4rem;
}
.box-captura-float {
    position: relative;
    top: 2rem;
}
.icone-ecom-titulo {
    width: 1.6rem;
    margin-right: 0.6rem;
}
.naofoipossivel {
    font-size: 1.7rem;
    text-align: center;
    background-color: yellow;
    padding: 1rem;
    border-radius: 1rem;
    color: #5C42DB;
}
.titulo-upload {
    margin: 0;
    margin-top: 0.6rem;
}
.gridwebapp {
    padding: 0;
    margin: 0;
}
.aguardandoassinatura {
    background-color: #ff8400;
    padding-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 18px;
    padding-bottom: 2px;
    min-height: 42px;
    text-align: center;
}
.assinado {
    background-color: #00b667;
    padding-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 18px;
    padding-bottom: 2px;
    min-height: 42px;
    text-align: center;
}
.linkaberto {
    background-color: #5c42db;
    padding-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 18px;
    padding-bottom: 2px;
    min-height: 42px;
    text-align: center;
}
.reprovado_texto {
    color: #e74c3c;
    font-weight: bold;
}
.aprovado_texto {
    color: #56ae40;
    font-weight: bold;
}

@media (min-width:961px)  { 
    .container-webpp {
        max-width: 500px;
        margin: 0 auto;
        background-color: #fff;
    }
    .MuiToolbar-regular {
        min-width: 500px;
    }
    .headerwebapp {
        display: flex;
        align-items: center;
    }
    #root {
        background-color: #f2f2f2;
    }
}