.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: #5C42DB;
    color: #fff;
}
.MuiFormLabel-root {
    color: black !important;
}
.MuiPaper-outlined {
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: #f5f5f5 !important;
}
.select-filtro {
    border: '1px solid #ced4da';
    border-radius: 4px;
    padding: '10px 26px 10px 12px';
}
.label-filtro {
    margin-left: 1rem;
    background-color: white;
    z-index: 999;
}
.label-filtro.MuiFormLabel-root.Mui-focused {
    top: -7px;
    background-color: white;
    z-index: 999;
}
.label-filtro2 {
    background-color: white;
    z-index: 999;
}
.label-filtro2.MuiFormLabel-root.Mui-focused {
    background-color: white;
    z-index: 999;
}
.icone-busca {
    fill: #dfe340 !important;
}
.grid-icone {
    text-align: right;
}
.search-icone {
    position: relative;
    top: 8px;
}
.makeStyles-tituloAcordeao-14 {
    color: #ffffff;
    position: relative;
    top: 8px;
}
.busca-avancada-icone {
    float: left;
    fill: #dfe340 !important;
}
.titulo-busca {
    color: #5C42DB;
}

.botao-incluir {
    background: #dfe340 !important;
    color: #5c42db !important;
    font-size: 0.7rem;
}
.ola_parceiro {
    text-align: right;
    width: 100%;
    max-width: 100%;
    padding-top: 1rem;
}
.ola_parceiro b {
    color: #5c42db !important;
}
.makeStyles-tituloAcordeao-65 {
    color: #ffffff;
    padding-top: 12px;
}
.botao-chamativo {
    display: inline-block !important;
    text-decoration: none !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #002328 !important;
    background-color: #eaef22 !important;
    border-radius: 15px !important;
    padding: 13px 30px !important;
    transition: 0.3s !important;
    box-shadow: none !important;
    border: 0 !important;
}
.botao-chamativo:hover {
    color: #002328 !important;
    box-shadow: 3px 3px #b6b904 !important;
    transition: 0.3s !important;
}
.icone-comissao {
    color: #C2C2C2;
}
.iconeatencao {
    position: relative;
    top: 41px;
    right: 0;
}
.h3atention {
    text-align: left;
    padding-left: 35px;
}

.fecharatencao{
    background: rgb(92, 66, 219) !important;
}
.papelfinger :hover {
    cursor: pointer;
}