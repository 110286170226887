.iconehome {
    width: 2rem;
}
.traco {
    position: relative;
    left: 0.8rem;
    bottom: 0.6rem;
}
.MuiTypography-displayBlock {
    color: #5C42DB !important;
}