.catavento {
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/catavento.png);
    background-size: cover;
    background-position: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
} 
.cabecanovo {
    width: 100% !important;
    max-width: 100% !important;
}
.logonovoregistrocabeca {
    width: 23rem !important;
    position: relative;
    right: 41px;
    bottom: 2rem;
}
.botaocriar {
    background: transparent !important;
}
.imagembotao {
    background: transparent !important;
}