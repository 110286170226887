@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');
.bgfundo {
    background-color: #5c42db !important;
}
.logonovo {
    width: 26rem !important;
}
.bemvindo {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 2.8rem;
    color: #FFFFFF;
    mix-blend-mode: normal;
    margin-bottom: 0;
}
.bemvindo span {
    font-weight: 700;
    font-size: 2.8rem;
}

.paragrafo-bemvindo {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    font-family: 'Lexend', sans-serif;
    color: #FFFFFF;
}

.box-login {
    padding-left: 2rem;
    padding-right: 1rem;
}

.botaonovo {
    background: transparent !important;
    width: 14rem !important;
    box-shadow: none !important;
}
.botaonovo:hover{
    box-shadow: none !important;
}

.textobotao {
    position: absolute;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    text-align: center;
    color: #3208FC;
}

.imagemfalando {
    max-width:100%;
    max-height:100%;
    height: auto;
    width:auto;
    padding-left: 2rem;
}

.form-input-label {
    font-family: 'Lexend' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 30px !important;
    color: #EAEF22 !important;
    position: relative !important;
}

.botaoacoes {
    font-family: 'Lexend' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    color: #EAEF22 !important;
    background: transparent !important;
    box-shadow: none !important;
    width: 100% ;
    max-width: 100% !important;
    font-size: 0.7rem !important;
}
.botaoacoesvoltar {
    font-family: 'Lexend' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    color: #EAEF22 !important;
    background: transparent !important;
    box-shadow: none !important;
    font-size: 0.7rem !important;
}
.gridbotoes {
    padding-top: 1rem;
}
.imagembotao {
    width: 12rem;
}
.botaotransparente {
    background-color: transparent !important;
}
.catavento {
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/catavento.png);
    background-size: cover;
    background-position: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.corponovoregistro {
    padding-left: 0 !important;
    padding-right: 1rem !important;
    margin-top: 1rem !important;
}
.corponovoregistroscrool {
    padding-left: 0 !important;
    padding-right: 54px !important;
    margin-top: 0 !important;
    overflow: auto;
    justify-content: start !important;
}

.corpoesqueci {
    justify-content: flex-start !important;
    margin-top: 0 !important;
    overflow: hidden !important;
    padding: 0 !important;
    overflow-y: auto !important;
}

.forcamargem {
    margin-left: 0 !important;
    margin-right: 1rem !important;
}


.box-login input {
    color: #FFFFFF !important;
    font-size: 1.3rem !important;
}
.box-login div.Mui-error {
    color: #EAEF22 !important;
}
.bgfundo input {
    color: #FFFFFF !important;
    font-size: 1.3rem !important;
}
.bgfundo div.Mui-error {
    color: #EAEF22 !important;
}
.botaocriar {
    background-color: transparent !important;
    box-shadow: none !important;
}
.botaonovo {
    color: #FFFFFF !important;
}
.botaonovo.Mui-disabled {
    display: none;
}

.botaonovovoltar {
    color: #EAEF22 !important;
    font-size: 0.7rem !important;
}

.logonovoregistro {
    width: 23rem !important;
    position: relative;
    right: 15rem;
    bottom: 2rem;
}

.logonovo {
    width: 26rem !important;
    position: relative;
    right: 3rem;
}

.gridcarafalando {
    text-align: right;
}
.bgfalando {
    background-position: right !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.formdelogin {
    justify-content: flex-start !important;
}
.tituloesqueci {
    color: #FFFFFF !important;
}
.subtituloesqueci {
    color: #FFFFFF !important;
}
.logoesqueci {
    width: 23rem !important;
    position: relative !important;
    right: 10rem !important;
}

@media (max-width:480px){
    .caixalogin {
        overflow: auto !important;
    }
    .logonovoregistro {
        width: 17rem !important;
        left: 3rem;
    }
    .logoesqueci {
        width: 17rem !important;
        left: 3rem;
    }
    .bgcanto {
        background-image: url(../../assets/images/formacanto.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: 25rem;
    }
    .bemvindo {
        font-size: 2rem;
    }
    .bemvindo span {
        font-size: 1.8rem;
        margin-top: 0;
    }
    .botaonovo {
        background: transparent !important;
        width: 11.8rem !important;
        box-shadow: none !important;
    }
    .logonovo {
        width: 17rem !important;
        position: relative;
        right: 2rem;
    }
    .box-login {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .gridbotoes {
        padding-top: 0rem;
    }
    .logonovoregistrocabeca {
        width: 18rem !important;
        position: relative;
        bottom: 2rem;
        right: 2rem;
    }
}


@media only screen   
and (min-width: 1030px)   
and (max-width: 1366px)  
{ 
    .logonovo {
        width: 22rem !important;
        position: relative;
        right: 3rem;
    }
    .bemvindo {
        font-family: 'Lexend', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 2rem;
        color: #FFFFFF;
        mix-blend-mode: normal;
        margin-bottom: 0;
        margin-top: 0;
    }
    .bemvindo span {
        font-weight: 700;
        font-size: 2rem;
    }
    .bgfundo input {
        color: #FFFFFF !important;
        font-size: 1rem !important;
    }
    .inputnovo {
        margin: 0 !important;
    }
    .cabecalogo {
        margin: 0 !important;
    }
    .corponovoregistro {
        justify-content: flex-start !important;
        margin-top: 0 !important;
    }
    .corponovoregistroscrool {
        justify-content: flex-start !important;
        margin-top: 0 !important;
    }
    .corpoesqueci {
        justify-content: flex-start !important;
        margin-top: 0 !important;
        overflow-x: hidden !important;
        padding: 0 !important;
        overflow-y: auto !important;
    }

    .forcamargem {
        margin-left: 0 !important;
        margin-right: 1rem !important;
    }
    
    .tituloesqueci {
        margin: 0 !important;
        padding: 0 !important;
    }
    .subtituloesqueci {
        margin: 0 !important;
        padding: 0 !important;
    }
    .boxnovo {
        margin: 0 !important;
    }
    .logoesqueci {
        width: 21rem !important;
        position: relative !important;
        right: 5rem !important;
    }

    .logonovoregistro {
        width: 21rem !important;
        position: relative;
        right: 5rem;
        bottom: 2rem;
    }
} 

.MuiButton-contained {
    background-color: transparent !important;
}



